import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Batch input for server creation/modification.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> BatchServerInput <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Hostname of server. FQDN = hostname + domain suffix of customer.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">hostname</GQLName>: <GQLLink href="/ecdn-api-scalars/String" mdxType="GQLLink">String</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ID of location this server belongs to.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">location</GQLName>: <GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Which hypervisor will this server run on.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">hypervisor</GQLName>: <GQLLink href="/ecdn-api-enums/Hypervisor" mdxType="GQLLink">Hypervisor</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Network interfaces of ECDN server.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">interfaces</GQLName>: [<GQLLink href="/ecdn-api-input-objects/InterfaceInput" mdxType="GQLLink">InterfaceInput</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># DNS servers used for name resolutions. Google resolvers will be used if omitted.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">dnsServers</GQLName>: [<GQLLink href="/ecdn-api-scalars/ServerAddress" mdxType="GQLLink">ServerAddress</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Address of NTP servers used by ECDN server. Ubuntu NTP pools will be used if omitted.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">ntpServers</GQLName>: [<GQLLink href="/ecdn-api-scalars/ServerAddress" mdxType="GQLLink">ServerAddress</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]</GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Log collector's address. Omit if log forwarding is disabled.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">logCollector</GQLName>: <GQLLink href="/ecdn-api-scalars/TransportAddress" mdxType="GQLLink">TransportAddress</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Community string of SNMP v2c. Omit if SNMP polling is disabled.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">snmpCommunityString</GQLName>: <GQLLink href="/ecdn-api-scalars/String" mdxType="GQLLink">String</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Is remote assistance enabled on server. Enabled by default.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">remoteAssistanceEnabled</GQLName>: <GQLLink href="/ecdn-api-scalars/Boolean" mdxType="GQLLink">Boolean</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`hostname: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/String"
      }}>{`String`}</a>{`!`}</h3>
    <p>{`Hostname of server. FQDN = hostname + domain suffix of customer.`}</p>
    <h3>{`location: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/ID"
      }}>{`ID`}</a>{`!`}</h3>
    <p>{`ID of location this server belongs to.`}</p>
    <h3>{`hypervisor: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Hypervisor"
      }}>{`Hypervisor`}</a>{`!`}</h3>
    <p>{`Which hypervisor will this server run on.`}</p>
    <h3>{`interfaces: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-input-objects/InterfaceInput"
      }}>{`InterfaceInput`}</a>{`!]!`}</h3>
    <p>{`Network interfaces of ECDN server.`}</p>
    <h3>{`dnsServers: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/ServerAddress"
      }}>{`ServerAddress`}</a>{`!]`}</h3>
    <p>{`DNS servers used for name resolutions. Google resolvers will be used if omitted.`}</p>
    <h3>{`ntpServers: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/ServerAddress"
      }}>{`ServerAddress`}</a>{`!]`}</h3>
    <p>{`Address of NTP servers used by ECDN server. Ubuntu NTP pools will be used if omitted.`}</p>
    <h3>{`logCollector: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/TransportAddress"
      }}>{`TransportAddress`}</a></h3>
    <p>{`Log collector’s address. Omit if log forwarding is disabled.`}</p>
    <h3>{`snmpCommunityString: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/String"
      }}>{`String`}</a></h3>
    <p>{`Community string of SNMP v2c. Omit if SNMP polling is disabled.`}</p>
    <h3>{`remoteAssistanceEnabled: `}<a parentName="h3" {...{
        "href": "/ecdn-api-scalars/Boolean"
      }}>{`Boolean`}</a></h3>
    <p>{`Is remote assistance enabled on server. Enabled by default.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      